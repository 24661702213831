import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
  selector: 'app-label',
  template: `
    <label for="{{ for }}" class="block mb-1 text-sm">{{ label }}</label>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelComponent {
  @Input() public for = '';
  @Input() public label = '';
}
