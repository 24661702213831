import { ComponentRef, inject, Injectable, Type, ViewContainerRef } from '@angular/core';
import { Injectors } from '../../utils/injectInjectors';
import { ViewportRuler } from '@angular/cdk/overlay';
import Intercom, { hide, update } from '@intercom/messenger-js-sdk';
import { MixpanelService } from '../../mixpanel/mixpanel.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public viewContainerRef?: ViewContainerRef;
  private modalsStack: ComponentRef<unknown>[] = [];
  private ruler = inject(ViewportRuler);
  private readonly mixpanelService = inject(MixpanelService);

  public close(component: unknown) {
    const rendered = this.modalsStack.find(modal => modal.instance === component);
    this.modalsStack = this.modalsStack.filter(modal => modal !== rendered);
    rendered?.destroy();
    if (this.modalsStack.length === 0) {
      update({ hide_default_launcher: false });
    }
  }

  public show<T>(component: Type<T>, injectors?: Injectors) {
    if (!this.viewContainerRef) {
      throw new Error('No view container ref');
    }

    if (this.ruler.getViewportSize().width < 768) {
      update({ hide_default_launcher: true });
    }

    const rendered = this.viewContainerRef.createComponent(component, injectors);
    this.modalsStack.push(rendered);

    this.mixpanelService.track('Modal Opened', { component: component.name });
    const overlay = rendered.location.nativeElement.querySelector('.modal-overlay');

    const escListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        this.close(rendered.instance);
      }
    };

    const outsideClickListener = (event: MouseEvent) => {
      if (event.target === overlay) {
        this.close(rendered.instance);
      }
    }

    document.addEventListener('keydown', escListener);

    setTimeout(() => overlay.addEventListener('click', outsideClickListener));
    rendered.onDestroy(() => {
      document.removeEventListener('keydown', escListener);
      overlay?.removeEventListener('click', outsideClickListener);
    });

    return rendered.instance;
  }
}
